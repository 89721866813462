import React from "react";
import styled from "styled-components"


export const CheckBoxStyled = styled.div`
  display: inline-block;
  > input {
    display: none;  /*デフォルトのチェックボックスを非表示にする*/
  }
  > input + label{
    position: relative;
    padding-left: 27px;   /*チェックボックス分の隙間を作る*/
    font-size: var(--fsize_base);
    line-height: 1.3rem;
    color: var(--clr_si_item);
    margin-right: 16px;
    margin-top: 6px;
    margin-bottom: 6px;
    min-width: 140px;
    &::before{
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 8px;
      left: 5px;
      transform: translateY(-50%);  /*中央ぞろえにする*/
      border: 1px solid var(--clr_si_border);
      border-radius: 3px;
      cursor: pointer;              /*ポインターを表示する*/
      background: var(--bkclr_si_item);
    }
  }
  > input:checked+label::before {
    background: cornflowerblue; /*チェックしたときの箱の色を変える*/
  }
  > input:checked+label::after {
    /*チェックの✓をつくる*/
    content: "";
    display: block;
    width: 12px;
    height: 7px;
    box-sizing: border-box;
    border-bottom: 2px solid var(--clr_si_checkline);
    border-left: 2px solid var(--clr_si_checkline);
    position: absolute;

    /*ここから下は位置を調整する*/
    top: 28%;
    left: 11px;
    /*箱を斜めに傾けてチェック表示にする*/
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg) translateY(-50%);
  }
`;


export default class CheckBox extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      isChecked :  false
    }
  }

  ToggleCheck = e => {
    this.setState({
      checked: e.target.checked,
    },() => this.props.callbackChange(e.target.checked));

  }

  render(){
    const { checked } = this.state;
    const { nm, lbnm } = this.props;

    return (
      <CheckBoxStyled><input type="checkbox" id={nm} name={nm} checked={checked} onChange={this.ToggleCheck} /><label htmlFor={nm}>{lbnm}</label></CheckBoxStyled>
    )
  }
}

// Propsのデフォルト
CheckBox.defaultProps = {
  checked: false,   // デフォルトはチェックなし
  name   : '',      // ラベル名空白
  lbnm   : '',      // ラベル名空白
}
