// import React from "react";
// import React, {useState} from "react";
// import { FiArrowRight } from "react-icons/fi";
// import {Link, useNavigate} from "react-router-dom";
import styled from "styled-components"
// import styled, {css} from "styled-components"

// get our fontawesome imports
// import { faCaretSquareLeft, faCommentAlt, faTable, faMoneyCheck, faBuilding, faAddressCard, faPencilAlt, faHouseUser, faPaw } from "@fortawesome/free-solid-svg-icons";
// import { faCaretSquareLeft, faCommentAlt, faTable, faBuilding, faFolderOpen, faPencilAlt, faIdCardAlt, faMoneyCheck, faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
// import { faCalendar } from "@fortawesome/free-regular-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import ConfirmPopup from "./partsConfirm";

//--------------------------------------------------------------------------------------------------
//　データ部
//--------------------------------------------------------------------------------------------------
/*------------------------*
 * 明細データ部            *
 *------------------------*/
export const MeisaiData = styled.div`
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 8px;
`;

export const MeisaiOperation = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const MeisaiOptionMenu = styled.div`
  display: flex;
  position: absolute;
  top: calc(var(--meisai_h) + 6px);
  right: 2px;
  align-items: center;
  font-size: var(--fsize_base);

  & ul li {
    display: flex;
    align-items: center;
    background: #fff;
    /* height: var(--meisai_h); */
    border: 1px solid #8a8a8a;
  }
  & li:not(:first-child) {
    margin-top: -1px;
  }
  & ul li a {
    text-decoration: none;
    font-size: var(--fsize_base);
    color: var(--clr_base_black);
    display: block;
    padding: 6px 20px;
  }
`;

export const MeisaiSibori = styled.div`
  & select {
    border: 1px solid var(--clr_dt_sibori_border);
    margin-top: 1px;
    padding: 2px 4px;
  }
`;

export const MeisaiTabList = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--clr_dt_tablst_border);
  justify-content: flex-end;
  margin-left: auto;

  & input[type="radio"] {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
  }

  & label {
    border: 1px solid var(--clr_dt_tablst_border);
    border-bottom: none;
    padding: 8px 0px 4px;
    border-radius: 8px 8px 0 0;
    background: var(--bkclr_dt_tab);
    color: var(--clr_dt_tab);
    font-size: var(--fsize_base);
    margin-right: 2px;
    width: 104px;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
  }
  & label:last-child {
    width: 50px;
    padding-top: 10px;
  }
  & input[type="radio"]:checked + label {
    background-color: var(--bkclr_dt_tab_check);
    color: var(--clr_dt_tab_check);
  }
  & label:hover {
    opacity: 0.9;
    /* font-size: calc(var(--fsize_base) + 1px); */
    /* font-weight: 700; */
    /* padding: 6px 0px 4px; */
  }
  /* div.dataline div.dt_tablst label:last-child:hover{
    padding-top: 8px;
  } */
`;

/*------------------------*
 * 明細レコード            *
 *------------------------*/
export const Meisai = styled.div`
  width: 100%;
  overflow: hidden;
  &.header{
    z-index: 1;
    border-left: 1px solid var(--clr_dt_meittl_border);
    border-right: 1px solid var(--clr_dt_meittl_border);
    box-shadow: 0 1px 5px rgba(0,0,0,0.2);
  }
  &.list{
    border: 1px solid #ccc;
    overflow-y: auto;
    height: calc(100vh - 264px - var(--meisai_h));
  }
  & table {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: var(--fsize_base);
    /* overflow: auto; */
    table-layout: fixed;
    width: 98vw;

    & tr {
      height: var(--meisai_h);
      background-color: var(--bkclr_dt_meisai);

      &:nth-child(even) {
        background-color: var(--bkclr_dt_meisai_even);
      }

      & th {
        border: none;
        /* border-top: 1px solid var(--clr_dt_meisai_border); */
        /* border-bottom: 1px solid var(--clr_dt_meisai_border); */
        /* background: var(--bkclr_dt_meittl); */
        overflow: hidden;
        border-right: 1px solid var(--clr_dt_meittl_border);
        padding: 2px 0px;
        text-overflow: ellipsis;
        /* font-weight: 700; */
        color: var(--clr_dt_meisai);
        font-size: var(--fsize_base);
        line-height: var(--lineh_base);
        background-image: linear-gradient(0deg, var(--bkclr_dt_meittl), var(--clr_base_white) 60%);
        &:nth-child(1) {
          width: var(--meisai_item_01);
          max-width: var(--meisai_item_01);
        }
        &:nth-child(2) {
          width: var(--meisai_item_02);
          max-width: var(--meisai_item_02);
        }
        &:nth-child(3) {
          width: var(--meisai_item_03);
          max-width: var(--meisai_item_03);
        }
        &:nth-child(4) {
          width: var(--meisai_item_04);
          max-width: var(--meisai_item_04);
        }
        &:nth-child(5) {
          width: var(--meisai_item_05);
          max-width: var(--meisai_item_05);
        }
        &:nth-child(6) {
          width: var(--meisai_item_06);
          max-width: var(--meisai_item_06);
        }
        &:nth-child(7) {
          width: var(--meisai_item_07);
          max-width: var(--meisai_item_07);
        }
        &:nth-child(8) {
          width: var(--meisai_item_08);
          max-width: var(--meisai_item_08);
        }
        &:nth-child(9) {
          width: var(--meisai_item_09);
          max-width: var(--meisai_item_09);
        }
        &:nth-child(10) {
          width: var(--meisai_item_10);
          max-width: var(--meisai_item_10);
        }
        &:nth-child(11) {
          width: var(--meisai_item_11);
          max-width: var(--meisai_item_11);
        }
        &:nth-child(12) {
          width: var(--meisai_item_12);
          max-width: var(--meisai_item_12);
        }
        &:nth-child(13) {
          width: var(--meisai_item_13);
          max-width: var(--meisai_item_13);
        }
        &:nth-child(14) {
          width: var(--meisai_item_14);
          max-width: var(--meisai_item_14);
        }
        &:nth-child(15) {
          width: var(--meisai_item_15);
          max-width: var(--meisai_item_15);
        }
      }
      & td {
        border-bottom: 1px solid var(--clr_dt_meisai_border);
        overflow: hidden;
        white-space: nowrap;
        padding: 2px 4px;
        border-right: 1px solid var(--clr_dt_meisai_border);
        text-overflow: ellipsis;
        font-size: var(--fsize_base);
        line-height: var(--lineh_base);
        &:nth-child(1) {
          width: var(--meisai_item_01);
          max-width: var(--meisai_item_01);
          text-align: var(--meisai_talgn_01);
        }
        &:nth-child(2) {
          width: var(--meisai_item_02);
          max-width: var(--meisai_item_02);
          text-align: var(--meisai_talgn_02);
        }
        &:nth-child(3) {
          width: var(--meisai_item_03);
          max-width: var(--meisai_item_03);
          text-align: var(--meisai_talgn_03);
        }
        &:nth-child(4) {
          width: var(--meisai_item_04);
          max-width: var(--meisai_item_04);
          text-align: var(--meisai_talgn_04);
        }
        &:nth-child(5) {
          width: var(--meisai_item_05);
          max-width: var(--meisai_item_05);
          text-align: var(--meisai_talgn_05);
        }
        &:nth-child(6) {
          width: var(--meisai_item_06);
          max-width: var(--meisai_item_06);
          text-align: var(--meisai_talgn_06);
        }
        &:nth-child(7) {
          width: var(--meisai_item_07);
          max-width: var(--meisai_item_07);
          text-align: var(--meisai_talgn_07);
        }
        &:nth-child(8) {
          width: var(--meisai_item_08);
          max-width: var(--meisai_item_08);
          text-align: var(--meisai_talgn_08);
        }
        &:nth-child(9) {
          width: var(--meisai_item_09);
          max-width: var(--meisai_item_09);
          text-align: var(--meisai_talgn_09);
        }
        &:nth-child(10) {
          width: var(--meisai_item_10);
          max-width: var(--meisai_item_10);
          text-align: var(--meisai_talgn_10);
        }
        &:nth-child(11) {
          width: var(--meisai_item_11);
          max-width: var(--meisai_item_11);
          text-align: var(--meisai_talgn_11);
        }
        &:nth-child(12) {
          width: var(--meisai_item_12);
          max-width: var(--meisai_item_12);
          text-align: var(--meisai_talgn_12);
        }
        &:nth-child(13) {
          width: var(--meisai_item_13);
          max-width: var(--meisai_item_13);
          text-align: var(--meisai_talgn_13);
        }
        &:nth-child(14) {
          width: var(--meisai_item_14);
          max-width: var(--meisai_item_14);
          text-align: var(--meisai_talgn_14);
        }
        &:nth-child(15) {
          width: var(--meisai_item_15);
          max-width: var(--meisai_item_15);
          text-align: var(--meisai_talgn_15);
        }
      }
    }
  }
`;

/*------------------------*
 * ページ制御              *
 *------------------------*/
export const PageCtrl = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0 0 auto;

  & div:not(:nth-child(3)) {
    margin: 3px 10px;
    background-color: var(--bkclr_pagebtn);
    color: var(--clr_pagebtn);
    padding: 8px 24px;
    border-radius: 0px;
    cursor: pointer;
    font-size: var(--fsize_base);
  }
  & div:not(:nth-child(3)):hover {
    background-color: var(--bkclr_pagebtn_hover); /* #9f0710 #7f0200 */
    color: var(--clr_pagebtn_hover);
  }
  & div:nth-child(3) {
    margin: 3px 10px;
    padding: 0;
    background-color: var(--bkclr_pageslct);
  }
  & div:nth-child(3) select {
    font-size: var(--fsize_base);
    border: 1px solid var(--clr_pageslct_border);
    width: 100px;
    text-align: center;
    padding: 6px;
    border-radius: 0px;
    margin-top: 0;
  }
  & div:nth-child(3) select:hover {
    background-color: var(--bkclr_pageslct_hover); /* #9f0710 #7f0200 */
    color: var(--clr_pageslct_hover);
  }
  & div:nth-child(3) select:focus {
    background-color: var(--bkclr_pageslct_focus);
    color: var(--clr_pageslct_focus);
  }

`;

