import React, {useState} from "react";
// import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
// import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

// import styled from "styled-components"

import {Header, Menu} from "./partsHeader";
import {Main, FuncLine, FuncTitle} from "./partsMain";
import {MeisaiData, MeisaiOperation, MeisaiOptionMenu, MeisaiSibori, MeisaiTabList, Meisai, PageCtrl} from "./partsListData";
import {SearchItemList, SearchItemDate, SearchItemUser, SearchItemNumber, SearchItemText, SearchItemCklst} from "./partsSearch";

import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";


function TimeLine(props) {

  let [tabidx, changeTabidx] = useState(0);
  let [dspcnt, changeDspcnt] = useState(50);

  let [tablst] = useState([
    {id: 0, name: "fctab0", title: "タイムライン", checked: true},
    {id: 1, name: "fctab1", title: "絞込検索"      , checked: false},
  ]);
  let [dsplst] = useState([
    {id:  50, title: '50件 表示' , selected: true},
    {id: 100, title: '100件 表示', selected: false},
    {id: 200, title: '200件 表示', selected: false},
    {id: 300, title: '300件 表示', selected: false},
  ]);

  let dspsrhnote = "全件対象";
  let unm = "臨時アカウント";
  let pgnavi = ["Timeline","タイムライン"].join(" ＞ ");
  let navigate = useNavigate();

  return(
<div>
  <Header unm={unm} pgnavi={pgnavi} navigate={navigate} />
  <Menu isMainMenu="true" />
  <Main>
    <FuncLine icon={faCommentAlt} title="TIME LINE" tablst={tablst} changeTabidx={changeTabidx} />
    <TimelineFuncTitle tabidx={tabidx} dspsrhnote={dspsrhnote} dsplst={dsplst} changeDspcnt={changeDspcnt} />
    <TimelineDataArea tabidx={tabidx} />
    <TimelineActionBtn tabidx={tabidx} />
  </Main>
</div>
  );

}

export default TimeLine;

function TimelineFuncTitle(props){

    function TargetFuncTitle(){
        switch(props.tabidx){
            case 0:
                return (
                    <FuncTitle totalcnt="101,000" title="最新の活動内容を表示しています。" dspsrhnote={props.dspsrhnote} dsplst={props.dsplst} changeDspcnt={props.changeDspcnt} />
                );
            case 1:
                return (
                    <FuncTitle title="活動内容を絞り込み検索します。" />
                );
            default:
                return null;
        }
    }

    return(
        <TargetFuncTitle />
    );
}

function TimelineDataArea(props){

    let [actlst] = useState([
        {id:  1, title: 'テレアポ'                     , checked: true},
        {id:  2, title: '（選択してはいけません）___不在', checked: false},
        {id:  3, title: '（選択してはいけません）___在宅', checked: false},
        {id:  4, title: '電話対応'                     , checked: false},
        {id:  5, title: '商談'                         , checked: false},
        {id:  6, title: '施工'                         , checked: false},
        {id:  7, title: '電話・メール'                  , checked: false},
        {id:  8, title: '社内会議・打合せ'               , checked: false},
        {id:  9, title: '社内作業'                      , checked: false},
        {id: 10, title: 'メンテナンス'                  , checked: false},
        {id: 11, title: '事務'                         , checked: false},
    ]);

    let [stslst] = useState([
        {id:  1, title: '不可'   , checked: true},
        {id:  2, title: '居留守' , checked: false},
        {id:  3, title: '不在'   , checked: false},
        {id:  4, title: 'チラシ' , checked: false},
        {id:  5, title: 'IO'     , checked: false},
        {id:  6, title: 'FO'     , checked: false},
        {id:  7, title: 'TO'     , checked: false},
        {id:  8, title: 'TO(外)' , checked: false},
        {id:  9, title: 'アポ取得', checked: false},
        {id: 10, title: 'AO'     , checked: false},
        {id: 11, title: '商談'   , checked: false},
    ]);

    let [arealst] = useState([
        {id:  1, title: '選択しない', checked: true},
        {id:  2, title: '九州'     , checked: false},
        {id:  3, title: '中国'     , checked: false},
        {id:  4, title: '関東'     , checked: false},
        {id:  5, title: '関西'     , checked: false},
        {id:  6, title: '東海'     , checked: false},
    ]);

    let [typelst] = useState([
        {id:  0, title: '選択しない'       , checked: true},
        {id:  1, title: 'ダイレクトセールス', checked: true},
        {id:  2, title: 'テレアポ（架電）'  , checked: true},
        {id:  3, title: 'テレアポ（反響）'  , checked: true},
        {id:  4, title: '外部顧客（架電）'  , checked: true},
        {id:  5, title: '外部顧客（反響）'  , checked: true},
        {id:  6, title: '2次営業*架電'     , checked: true},
        {id:  7, title: '2次営業*メンテ'    , checked: true},
        {id:  8, title: '2次営業*営業打診'  , checked: true},
        {id:  9, title: '新規*DM反響'      , checked: true},
        {id: 10, title: '紹介'             , checked: true},
    ]);

    function TargetDataArea(){
        switch(props.tabidx){
            case 0:
                return (
                    <MeisaiData>
                        <MeisaiOperation>
                            <MeisaiSibori>
                                <span>
                                    <select name="cmbBumonId" id="cmbBumonId" >
                                        <option defaultValue="0">－－部署名－－</option>
                                        <option defaultValue="1">株式会社comam</option>
                                        <option defaultValue="2">&emsp;株式会社comamテクノロジー</option>
                                        <option defaultValue="3">&emsp;本社営業部</option>
                                        <option defaultValue="4">&emsp;メンテＲＦ部</option>
                                        <option defaultValue="5">&emsp;営業管理部</option>
                                        <option defaultValue="6">&emsp;総務部</option>
                                        <option defaultValue="7">&emsp;経理部</option>
                                        <option defaultValue="8">&emsp;◎メンテナンス共有</option>
                                        <option defaultValue="9">&emsp;メンテナンス</option>
                                        <option defaultValue="10">&emsp;臨時</option>
                                    </select>
                                </span>
                                <span>
                                    <select name="cmbUserId" id="cmbUserId">
                                        <option defaultValue="0">－－社員名－－</option>
                                        <option defaultValue="1">社員Ａさん</option>
                                        <option defaultValue="2">社員Ｂさん</option>
                                        <option defaultValue="3">社員Ｃさん</option>
                                    </select>
                                </span>
                            </MeisaiSibori>
                            <MeisaiTabList>
                                <input type="radio" id="dttab0"  name="dttab" defaultValue="0" /><label htmlFor="dttab0">基本</label>
                                <input type="radio" id="dttab1"  name="dttab" defaultValue="1" /><label htmlFor="dttab1">拡張①</label>
                                <input type="radio" id="dttab2"  name="dttab" defaultValue="2" /><label htmlFor="dttab2">拡張②</label>
                                <input type="radio" id="dttab3"  name="dttab" defaultValue="3" /><label htmlFor="dttab3">拡張③</label>
                                <input type="radio" id="dttab4"  name="dttab" defaultValue="4" /><label htmlFor="dttab4">拡張④</label>
                                <input type="radio" id="dttab99" name="dttab" defaultValue="99" /><label htmlFor="dttab99">▼</label>
                            </MeisaiTabList>
                            <MeisaiOptionMenu id="dt_optmenu" className="nondisp">
                                <ul>
                                    <li>表示項目変更</li>
                                </ul>
                            </MeisaiOptionMenu>
                        </MeisaiOperation>
                        <Meisai className="header">
                            <table><tbody>
                                <tr>
                                    <th>日付</th>
                                    <th>開始時刻</th>
                                    <th>終了時刻</th>
                                    <th>報告者</th>
                                    <th>世帯名</th>
                                    <th>案件</th>
                                    <th>活動内容</th>
                                    <th>進捗状況</th>
                                    <th>業務タイプ</th>
                                    <th>管轄エリア</th>
                                </tr>
                            </tbody></table>
                        </Meisai>
                        <Meisai className="list">
                            <table><tbody>
                                <tr>
                                    <td>2021/11/22</td>
                                    <td>20:40</td>
                                    <td>20:45</td>
                                    <td>社員Ａさん</td>
                                    <td>山下 コマム様</td>
                                    <td>■</td>
                                    <td>事務(5分)</td>
                                    <td>商談</td>
                                    <td>■省エネ</td>
                                    <td>中国</td>
                                </tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                                <tr><td>2021/11/22</td><td>20:40</td><td>20:45</td><td>社員Ａさん</td><td>山下 コマム様</td><td>■</td><td>事務(5分)</td><td>商談</td><td>■省エネ</td><td>中国</td></tr>
                            </tbody></table>
                        </Meisai>
                        <PageCtrl>
                            <div>先頭へ</div>
                            <div>前へ</div>
                            <div>
                                <select name="cmbPageCnt" id="cmbPageCnt" >
                                    <option defaultValue="1">1</option>
                                    <option defaultValue="2">2</option>
                                    <option defaultValue="3">3</option>
                                    <option defaultValue="4">4</option>
                                    <option defaultValue="5">5</option>
                                    <option defaultValue="6">6</option>
                                    <option defaultValue="7">7</option>
                                    <option defaultValue="8">8</option>
                                    <option defaultValue="9">9</option>
                                    <option defaultValue="10">10</option>
                                    <option defaultValue="11">11</option>
                                    <option defaultValue="12">12</option>
                                </select>
                            </div>
                            <div>次へ</div>
                            <div>最終へ</div>
                        </PageCtrl>
                    </MeisaiData>
                );
            case 1:

                return (

                <SearchItemList>
                    <ul>
                        <li>日時</li>
                        <SearchItemDate no="1" />
                        <li>報告者</li>
                        <SearchItemUser no="2" />
                        <li>世帯コード</li>
                        <SearchItemNumber no="3" placeholder="世帯コードを入力して下さい。" value="" />
                        <li>世帯名</li>
                        <SearchItemText no="4" placeholder="世帯コードを入力して下さい。" value="" checked="true" />
                        <li>活動内容</li>
                        <SearchItemCklst no="5" list={actlst} />
                        <li>進捗状況</li>
                        <SearchItemCklst no="6" list={stslst} />
                        <li>管轄エリア（アポ取得）</li>
                        <SearchItemCklst no="7" list={arealst} />
                        <li>発生タイプ</li>
                        <SearchItemCklst no="8" list={typelst} />
                    </ul>
                    <div className="srhOR">
                        <input type="checkbox" className="si_ckbtn" id="chkSI_srhOR" name="chkSI_srhOR" value="1" /><label htmlFor="chkSI_srhOR">上記項目をOR条件で検索（AまたはB）</label>
                    </div>
                </SearchItemList>

                );
            default:
                return null;
        }
    }

    return(
        <TargetDataArea />
    );
}

function TimelineActionBtn(props){

    function TargetActionBtn(){
        switch(props.tabidx){
            case 0:
                return null;
            case 1:
                return (
                    <div className="actionbtn">
                        <button type="button" id="btnCancel" name="btnCancel">キャンセル</button>
                        <button type="button" id="btnSearch" name="btnSearch">検索</button>
                    </div>
                );
            default:
                return null;
        }
    }

    return (
        <TargetActionBtn />
    );
}
