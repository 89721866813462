import React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Login from './pages/pageLogin';
import Menu from './pages/pageMenu';
import EneSav from './pages/pageTimeline';
import Dashboard from './pages/pageDashboard';
import Calender from './pages/pageCalender';
import Household from './pages/pageHousehold';
import Contract from './pages/pageContract';
import Action from './pages/pageAction';
import Worker from './pages/pageWorker';
import Product from './pages/pageProduct';
import Notfound from './pages/pageNotfound';

export default function PageRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Notfound />} />
        <Route path="/" element={<Login />} />
        <Route path="/Menu" element={<Menu />} />
        <Route path="/EneSav" element ={<EneSav />} />
        <Route path="/Timeline" element ={<EneSav />} />
        <Route path="/Dashboard" element ={<Dashboard />} />
        <Route path="/Calender" element ={<Calender />} />
        <Route path="/Household" element ={<Household />} />
        <Route path="/Contract" element ={<Contract />} />
        <Route path="/Action" element ={<Action />} />
        <Route path="/Worker" element ={<Worker />} />
        <Route path="/Product" element ={<Product />} />
      </Routes>
    </BrowserRouter>
  );
}
