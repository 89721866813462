import React from 'react';

import './styles/config.css';
import './styles/reset.css';
import './styles/common.css';

import PageRouter from './pageRouter';

function App() {
  return (
    <PageRouter />
  );
}

export default App;