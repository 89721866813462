import React, {useState} from "react";
// import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
// import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

// import styled from "styled-components"

import {Header, Menu} from "./partsHeader"
import {Main, FuncLine, FuncTitle} from "./partsMain"


import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";



function Contract() {

  var [tabidx, changeTabidx] = useState(0);
  var [dspcnt, changeDspcnt] = useState(50);

  var [tablst] = useState([
    {id: 0, name: "fctab0", title: "案件一覧", checked: true},
    {id: 1, name: "fctab1", title: "案件検索", checked: false},
    {id: 2, name: "fctab2", title: "新規登録", checked: false},
  ]);
  var [dsplst] = useState([
    {id:  50, title: '50件 表示' , selected: true},
    {id: 100, title: '100件 表示', selected: false},
    {id: 200, title: '200件 表示', selected: false},
    {id: 300, title: '300件 表示', selected: false},
  ]);

  var dspsrhnote = "全件対象";

  let unm = "臨時アカウント";
  let pgnavi = ["Contract","案件一覧"].join(" ＞ ");
  let navigate = useNavigate();

  return(
<div>
  <Header unm={unm} pgnavi={pgnavi} navigate={navigate} />
  <Menu isMainMenu="true" />
  <Main>
    <FuncLine icon={faFolderOpen} title="CONTRACT" tablst={tablst} changeTabidx={changeTabidx} />
    <FuncTitle totalcnt="101,000" title="【　案件一覧　】" dspsrhnote={dspsrhnote} dsplst={dsplst} changeDspcnt={changeDspcnt} />
    <div>tabidx={tabidx}</div>
    <div>dspcnt={dspcnt}</div>
  </Main>
</div>
  );

}

export default Contract;

