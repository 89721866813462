import React, {useState} from "react";
// import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import styled from "styled-components"

import ImgMenuHover from "../images/足跡_赤600px.png";
import ImgMenuHover2 from "../images/足跡_600px.png";

import ConfirmPopup from "./partsConfirm";

const MainForm = styled.div`
display: flex;
flex-wrap: wrap;
width: 100vw;
max-width: 1920px;
overflow: hidden;
margin: 0 auto;
line-height: 1.5;
`;

const MenuIcon = styled.div`
display: flex;
width: calc(33.3% - 22px);
align-items: center;
justify-content: center;
height: calc(33.3vh - 22px);
/* border: 1px solid #ddd; */
margin: 10px;
background-color: var(--bkclr_menu01);
a {
  font-size: var(--fsize_menu);
  text-decoration: none;
  color: var(--clr_menu01);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
&:nth-child(odd){
  /* background-color: #550201; */
  background-color: var(--bkclr_menu02);
  a {
    color: var(--clr_menu02);
  }
}
&:hover{
  /* border: 1px solid #ddd; */
  border-radius: 10px;
  transform: scale(1.1,1.1);  /* 回転 rotateY(360deg) */
  transition: 1s;             /* 1秒かけて動く */    

  background-image: url(${ImgMenuHover});
  background-size: 20%;
  background-repeat: no-repeat;
  background-position-y: 97%;
  background-position-x: 98%;  
  &:nth-child(odd){
    background-image: url(${ImgMenuHover2});
  }
}
`;

function Menu() {

  const [show, setShow] = useState(false);
  const [msg, setMsg]   = useState('');
  const [to, setTo]     = useState('');
  const showConfirmPopup = (e, msg, to) =>{
    e.preventDefault();
    setShow(true);
    setMsg(msg);
    setTo(to);
  }

  return(
<div>
  <MainForm>
    <MenuIcon><Link to="/Bookshelf">本棚</Link></MenuIcon>
    <MenuIcon><Link to="/Network">NETWORK<br/>✖&nbsp;各種URL</Link></MenuIcon>
    <MenuIcon><Link to="/" onClick={e => showConfirmPopup(e, '待機画面へ移動しますか？', '/')}>待機画面</Link></MenuIcon>
    <MenuIcon><Link to="/Living">リビング</Link></MenuIcon>
    <MenuIcon><Link to="/PlanDev">企画開発</Link></MenuIcon>
    <MenuIcon><Link to="/TotalMng">comam<br/>✖&nbsp;総合管理</Link></MenuIcon>
    <MenuIcon><Link to="/EneSav">営業&nbsp;✖&nbsp;省エネ</Link></MenuIcon>
    <MenuIcon><Link to="/TechMnt" >テクノロジー<br/>✖&nbsp;メンテナンス</Link></MenuIcon>
    <MenuIcon><Link to="/Calender">省エネ<br/>✖&nbsp;カレンダー</Link></MenuIcon>
  </MainForm>
  <ConfirmPopup show={show} setShow={setShow} msg={msg} to={to} />
</div>
  );
}

export default Menu;

