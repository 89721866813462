import React from "react";
import styled from "styled-components"

import {LnkBtn2} from "./contents"

const Popup = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
  z-index: 9999;
`;
const PopupInner = styled.div`
  position: absolute;
  left: 30%;
  right: 30%;
  top: 30%;
  // bottom: 25%;
  height: 40vh;
  margin: auto;
  background: white;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
  animation : modalOpenAnime 0.3s ease;
  @keyframes modalOpenAnime {                     /* フェードイン */
      0% { opacity: 0; top: -30% }
    100% { opacity: 1; top:  30% }
  }
`;
const Title = styled.h1`
  display: flex;
  height: 29vh; /* 76%; */
  justify-content: center;
  align-items: center;
  font-size: 18px;
  background: var(--clr_base02);
  color: var(--clr_base_white);
  border-radius: 10px 10px 0 0;
`;

const PopupBtn = styled(LnkBtn2)`
  margin: 3vh 3% 0;
  font-size: 18px;
  height: 3em;
  width: 10em;
`;

function ConfirmPopup({show,setShow,msg,to}){

  const doOk = function() {
    doCancel();
    window.location.href = to;
  }

  const doCancel = function(){
    setShow(false);
  }

  if(show){

    return (
<Popup onClick={doCancel}>
  <PopupInner onClick={(e) => e.stopPropagation()}>
    <Title>{msg}</Title>
    <PopupBtn onClick={doOk}>ＯＫ</PopupBtn>
    <PopupBtn onClick={doCancel}>キャンセル</PopupBtn>
  </PopupInner>
</Popup>
    );
  }else{
    return null;
  }

}

export default ConfirmPopup;
