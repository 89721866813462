import React from "react";
// import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";

import logoImg from "../images/logo.png";
import asiatoImg from "../images/足跡.png";
import asiatoImg2 from "../images/足跡_黒.png";
// import "../styles/pages/landing.css";

import "../styles/login.css";


export default class Login extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      isFocus : false,
    }

    this.refUid = React.createRef();
    this.refPassword = React.createRef();

    this.doInputFocus = this.doInputFocus.bind(this);
    this.doInputBlur = this.doInputBlur.bind(this);
  }

  doInputFocus(event){
    this.setState({isFocus : true});
  }
  doInputBlur(event){
    this.setState({isFocus : false});
  }

  doLabelClick(ref){
    ref.focus();
  }


  render(){

    var clsFormLabel   = 'formLabel ' + (this.state.isFocus ? 'formTop' : '');
    var clsFormWrapper = (this.state.isFocus ? 'darken-bg' : '');
    var clsLogoDiv     = 'logo ' + (this.state.isFocus ? 'logo-active' : '');

    return(
<div>
    <div id="formWrapper" className={clsFormWrapper}>

    <form method="GET" >
    <div id="form" >
    <div className={clsLogoDiv}>
        <img src={logoImg} alt="ロゴ" />
        <span>SYSTEM</span>
    </div>
        <div action="/Menu" className="form-item">
          <p className={clsFormLabel} onClick={e => this.doLabelClick(this.refUid.current)}>User Id</p>
          <input type="number" name="uid" id="uid" className="form-style" ref={this.refUid} autoComplete="off" onFocus={this.doInputFocus}  onBlur={this.doInputBlur} />
        </div>
        <div className="form-item">
          <p className={clsFormLabel} onClick={e => this.doLabelClick(this.refPassword.current)}>Password</p>
          <input type="password" name="password" id="password" className="form-style" ref={this.refPassword} onFocus={this.doInputFocus} onBlur={this.doInputBlur} />
        </div>
        <div className="form-item">
          <Link to='/Menu' className="login">Log&nbsp;In</Link>
        <div className="clear-fix"></div>
        </div>
      </div>
      </form>
    </div>

<img className="asiato" src={asiatoImg2} alt="足跡" style={{top: "60%" , left: "53%" , transform:"rotate(-5deg)"}} />
<img className="asiato" src={asiatoImg2} alt="足跡" style={{top: "61%",left: "44.7%",transform: "rotate(-5deg) scale(1.2)"}} />
<img className="asiato" src={asiatoImg}  alt="足跡" style={{top: "70%",left: "20%",transform: "rotate(-45deg)"}} />
<img className="asiato" src={asiatoImg}  alt="足跡" style={{top: "69%",left: "19%",transform: "rotate(-46deg)"}} />
<img className="asiato" src={asiatoImg}  alt="足跡" style={{top: "68.5%",left: "17.8%",transform: "rotate(-69deg)"}} />
<img className="asiato" src={asiatoImg}  alt="足跡" style={{top: "68.5%",left: "16.4%",transform: "rotate(-98deg)"}} />
<img className="asiato" src={asiatoImg}  alt="足跡" style={{top: "68.7%",left: "15.1%",transform: "rotate(-118deg)"}} />
<img className="asiato" src={asiatoImg}  alt="足跡" style={{top: "69.2%",left: "13.8%",transform: "rotate(-143deg)"}} />
<img className="asiato" src={asiatoImg}  alt="足跡" style={{top: "70.3%",left: "12.8%",transform: "rotate(-153deg)"}} />
<img className="asiato" src={asiatoImg}  alt="足跡" style={{top: "5px",left: "5px"}} />
<img className="asiato" src={asiatoImg}  alt="足跡" style={{top: "5px",left: "5px"}} />
<img className="asiato" src={asiatoImg}  alt="足跡" style={{top: "5px",left: "5px"}} />
<img className="asiato" src={asiatoImg}  alt="足跡" style={{top: "5px",left: "5px"}} />
<img className="asiato" src={asiatoImg}  alt="足跡" style={{top: "5px",left: "5px"}} />
<img className="asiato" src={asiatoImg}  alt="足跡" style={{top: "5px",left: "5px"}} />
<img className="asiato" src={asiatoImg}  alt="足跡" style={{top: "5px",left: "5px"}} />
<img className="asiato" src={asiatoImg}  alt="足跡" style={{top: "5px",left: "5px"}} />

</div>
    );
  }


}

