import React from "react";
import styled from "styled-components"


import { faAngleUp, faAngleDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
const SelectHeader = styled.button`
  display: flex;
  padding: 0px 8px;
  margin: 0;
  font-size: var(--fsize_base);
  border: none;
  background: var(--clr_base_white);
  color: var(--clr_base_black);
  border: 2px solid var(--clr_base_white);
  border-left: 2px solid var(--clr_base);
  border-right: 2px solid var(--clr_base);
  border-radius: 8px;
  cursor: pointer;
  :focus{
    border: 2px solid var(--clr_base_strong);
    padding: 0px 8px;
  }
  & div{
    font-family: var(--font_family);
    padding-right: 8px;
    font-weight: 700;
  }

`;
const SelectIcon = styled(FontAwesomeIcon)`
  font-size: calc(var(--fsize_base) * 1.5);
  color: var(--clr_base);
`;
const SelectCheckIcon = styled(FontAwesomeIcon)`
  color: var(--clr_base);
`;

const SelectList = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 4px;
  color: val(--clr_base_black);
  z-index: 100;
  button{
    font-family: var(--font_family);
    width: 100%;
    text-align: left;
    padding-right: 4px;
    white-space: nowrap;
    background: var(--clr_base_white);
    border: 1px solid #ccc;
    padding: 2px 16px;
    margin-bottom: -1px;
    cursor: pointer;
    :nth-child(even){
      background: var(--clr_base06);
    }
    :not(:first-child){
      border-top-style: dotted;
    }
  }
`;





export default class Select extends React.Component {

  headerTitle = '';

  constructor(props){
    super(props)

    var inittl = "";
    this.props.list.forEach(item => {
      if(item.id === this.props.selectedIndex){
        inittl = item.title;
      }
    });

    this.state = {
      isListOpen : false,
      isMouseOver: false,
      headerTitle: inittl,
    }
  }

  toggleList = () => {
    this.setState(prevState => ({
      isListOpen: !prevState.isListOpen
    }));
  }

  closeList = () => {
    if(!this.state.isMouseOver){
      this.setState({
        isListOpen: false
      });
    }
  }

  mouseOver = () => {
    this.setState({
      isMouseOver: true
    });
  }

  mouseOut = () => {
    this.setState({
      isMouseOver: false
    });
  }

  selectItem = (item) => {
    const { title, id } = item;

    this.setState({
      headerTitle: title,
      isListOpen: false,
    },() => this.props.callbackChange(id));

    const temp = this.props.list;
    temp.forEach(item => {
      if(item.id === id){
        item.selected = true;
      }else{
        item.selected = false;
      }
    });

  }

  render(){
    const { isListOpen, headerTitle } = this.state;
    const { list } = this.props;

    return (
      <SelectWrapper>
        <SelectHeader
          type="button"
          onClick={this.toggleList}
          onBlur ={this.closeList}
        >
          <div>{headerTitle}</div>
          {isListOpen
            ? <SelectIcon icon={faAngleUp} />
            : <SelectIcon icon={faAngleDown} />}
        </SelectHeader>
        {isListOpen && (
          <SelectList
            role="list"
            onMouseOver={() => this.mouseOver()}
            onMouseOut={() => this.mouseOut()}
          >
            {list.map((item) => (
              <button
                type="button"
                className="dd-list-item"
                key={item.id}
                onClick={() => this.selectItem(item)}
              >
                {item.title}
                {' '}
                {item.selected && <SelectCheckIcon icon={faCheck} />}
              </button>
            ))}
          </SelectList>
        )}
      </SelectWrapper>
    )
  }
}

