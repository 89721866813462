import React, {useState} from "react";
// import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
// import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

// import styled from "styled-components"

import {Header, Menu} from "./partsHeader"
import {Main, FuncLine, FuncTitle} from "./partsMain"


import { faTable } from "@fortawesome/free-solid-svg-icons";

function Dashboard() {

  var [tabidx, changeTabidx] = useState(0);
  var [listno, changeListno] = useState(0);
  var [dspcnt, changeDspcnt] = useState(50);

  var [tablst] = useState([
    {id: 0, name: "fctab0", title: "ダッシュボード", checked: true},
    {id: 1, name: "fctab1", title: "新規登録"      , checked: false},
  ]);
  var [dsplst] = useState([
    {id:  50, title: '50件 表示' , selected: true},
    {id: 100, title: '100件 表示', selected: false},
    {id: 200, title: '200件 表示', selected: false},
    {id: 300, title: '300件 表示', selected: false},
  ]);
  var [dashlst] = useState([
    {id: 0, title: '【全】全体案件一覧', selected: true},
    {id: 1, title: '個人ダッシュボード', selected: false},
    {id: 2, title: '▲営業用*共有▲'    , selected: false},
    {id: 3, title: '■メンテ用'        , selected: false},
    {id: 4, title: '先月アクセスログ' , selected: false},
    {id: 5, title: '今月アクセスログ' , selected: false},
  ]);

  let unm = "臨時アカウント";
  let pgnavi = ["Dashboard","ダッシュボード"].join(" ＞ ");
  let navigate = useNavigate();

  return(
<div>
    <Header unm={unm} pgnavi={pgnavi} navigate={navigate} />
    <Menu isMainMenu="true" />
    <Main>
      <FuncLine icon={faTable} title="DASH BOARD" tablst={tablst} changeTabidx={changeTabidx} />
      <FuncTitle totalcnt="101,000" dashlst={dashlst} changeListno={changeListno} dsplst={dsplst} changeDspcnt={changeDspcnt} />
      <div>tabidx={tabidx}</div>
      <div>listno={listno}</div>
      <div>dspcnt={dspcnt}</div>
    </Main>
</div>
  );
}

export default Dashboard;

