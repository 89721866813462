import React from "react";
// import React, {useState} from "react";
// import { FiArrowRight } from "react-icons/fi";
// import {Link, useNavigate} from "react-router-dom";
import {Link} from "react-router-dom";
import styled, {css} from "styled-components"
import logoImg from "../images/logo.png";
import accountImg from "../images/account_paw.svg"

// get our fontawesome imports
// import { faCaretSquareLeft, faCommentAlt, faTable, faMoneyCheck, faBuilding, faAddressCard, faPencilAlt, faHouseUser, faPaw } from "@fortawesome/free-solid-svg-icons";
import { faCaretSquareLeft, faCommentAlt, faTable, faBuilding, faFolderOpen, faPencilAlt, faIdCardAlt, faMoneyCheck, faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ConfirmPopup from "./partsConfirm";

//--------------------------------------------------------------------------------------------------
//　ヘッダー部
//--------------------------------------------------------------------------------------------------
// ベーススタイル
const HeaderStyle = css`
  width: 100vw;
  height: 40px;
  max-height: 40px;
  display: flex;
  flex-wrap: nowrap;
  background-color: var(--bkclr_header);
  padding-left: 20px;
  & div#logo{
    padding: 0px 12px 0 2px;
    background-color: var(--bkclr_logo);
    & img{
      height: 38px;
    }
  }
  & div#backpage{
    width: 40px;
    padding: 8px 0 0 11px;
    color: var(--clr_base_gray02);
    /* background: var(--clr_base_gray02); */
    font-size: var(--fsize_backpage);
    font-weight: 700;
    /* transform: scale(1.3); */
    /* border-right: 1px solid var(--clr_header); */
    /* margin-left: -11px; */
    /* margin: auto 13px; */
    cursor: pointer;
    &:hover{
      color: var(--clr_base05);
    }
  }
  & div#pgnavi{
    /* width: 40px; */
    padding: 15px 0 0 8px;
    color: var(--clr_header);
    font-size: var(--fsize_base);
    margin-left: -12px;
    & span{
      padding: 0px 5px;
      display: inline-block;
      transform: scaleX(0.5);
      font-size: var(--fsize_base);
      font-weight: 700;
    }
  }
  & div#menulst{
    margin-left: auto;
    display: flex;
    color: var(--clr_header);
    /* margin-right: 20px; */
    font-size: var(--fsize_base);
    span{
      margin-right: 8px;
      color: var(--clr_base_blue);
      border: 2px solid var(--clr_base_blue);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      width: 36px;
      font-size: 20px;
      border-radius: 18px;
    }
    img{
      margin-right: 8px;
      height: 24px;
      width: 24px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
    div{
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      /* border-left: 1px solid var(--clr_base02); */
      cursor: pointer;
      a{
        text-decoration: none;
        color: var(--clr_bsae_black);
        display: inline-flex;
        width: 100px;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
      :first-child a{
        width: 100%;
        justify-content: right;
      }
    }
    div:first-child{
      width: 240px;
      justify-content: flex-end;
      padding-right: 10px;
      border-left: none;
    }
    div:hover:not(:first-child){
      background-color: var(--clr_base03);
      color: var(--clr_base_white);
      a{
        color: var(--clr_base_white);
      }
    }
  }
`;

const HeaderLine = styled.header`
  ${HeaderStyle}
`;

export class Header extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      show : false,
      msg  : '',
      to   : '',
    };

    this.setShow = this.setShow.bind(this);
  }

  BackPage = <div id="backpage" onClick={() => this.props.navigate(-1)}><FontAwesomeIcon icon={faCaretSquareLeft}/></div>;

  showConfirmPopup = (e, msg, to) =>{
    e.preventDefault();
    this.setState({
      show : true,
      msg  : msg,
      to   : to,
    });
  }

  setShow(show){
    this.setState({show : show});
  }

  render(){

    return (
<HeaderLine>
  <div id="logo">
      <img src={logoImg} alt="ロゴ" />
  </div>
  {this.BackPage}
  <div id="pgnavi">{this.props.pgnavi}</div>
  <div id="menulst">
      <div><Link to="/" onClick={e => this.showConfirmPopup(e, 'ログアウトしますか？', '/')}><img src={accountImg} alt="アカウント"></img>{this.props.unm}</Link></div>
      <div>本棚</div>
      <div>NETWORK<br />✖&nbsp;各種URL</div>
      <div><Link to="/" onClick={e => this.showConfirmPopup(e, '待機画面へ移動しますか？', '/')}>待機画面</Link></div>
  </div>
  <ConfirmPopup show={this.state.show} setShow={this.setShow} msg={this.state.msg} to={this.state.to} />
</HeaderLine>
    );
  }
}

// export default Header;

const MenuLine = styled.div`
  width: 100vw;
  height: 32px;
  max-height: 32px;
  display: flex;
  flex-wrap: nowrap;
  background-color: var(--bkclr_menuline);
  padding: 0px 0px 0px 144px;
  font-size: var(--fsize_menuline);
  color: var(--clr_menuline);
  border-bottom: 1px solid var(--clr_menuline_border);
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
`;

const MenuItem = styled(Link)`
  text-decoration: none;
  color: var(--clr_bsae_white);
  width: 144px;
  text-align: center;
  border-right: 1px solid var(--clr_menuline_border);
  /* padding: 8px 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  font-size: var(--fsize_base);
  letter-spacing: 0.5px;
  &:hover{
    /* background-color: var(--clr_base_strong02); */
    background: -moz-linear-gradient(top, var(--clr_base_strong), var(--clr_base_strong02) 20%, var(--clr_base_strong) 130%);
    background: -webkit-linear-gradient(top, var(--clr_base_strong), var(--clr_base_strong02) 20%, var(--clr_base_strong) 130%);
    background: linear-gradient(to bottom, var(--clr_base_strong), var(--clr_base_strong02) 20%, var(--clr_base_strong) 130%);
    /* color: #fff; */
    /* padding-top: 6px; */
    transition: .3s;
    /* font-size: var(--fsize_menu_hover); */
    transform: scaleY(1.1) translateY(-5px);
    box-shadow: 0 1px 0px var(--clr_base_white);
    border-radius: 5px 5px 0 0;
  }
  &:first-child{
    border-left: 1px solid var(--clr_menuline_border);
  }
`;

const MenuToggle = styled.div`
  border-right: 1px solid var(--clr_menuline_border);
  width: 20px;
  color: var(--clr_base_white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  &:hover{
    background: var(--clr_base05);
    /* background-color: var(--clr_base_white);
    color: var(--clr_base_strong02);
    transform: translateY(-3px);
    border-top: 3px solid var(--clr_base_strong02);
    box-shadow: 0 3px 0px var(--clr_base_strong02); */
  }
`;

const MenuIcon = styled(FontAwesomeIcon)`
  color: var(--clr_menuicon);
  font-size: var(--fsize_menuicon);
  margin-right: 8px;
  margin-top: 0px;
`;

export class Menu extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      isMainMenu : this.props.isMainMenu,
    };
  }

  CallShowMain = () => {
    console.log("CallShowMain");
    this.setState({isMainMenu : true});
  }
  CallShowOther = () => {
    console.log("CallShowOther");
    this.setState({isMainMenu : false});
  }


  render(){

    return (
<div>
    {this.state.isMainMenu ?
<MenuLine id="menuline">
    <MenuItem to="/Timeline"><MenuIcon icon={faCommentAlt}/>Timeline</MenuItem>
    <MenuItem to="/Dashboard"><MenuIcon icon={faTable}/>Dashboard</MenuItem>
    <MenuItem to="/Calender"><MenuIcon icon={faCalendar}/>Calendar</MenuItem>
    <MenuItem to="/Household"><MenuIcon icon={faBuilding}/>Household</MenuItem>
    <MenuItem to="/Contract"><MenuIcon icon={faFolderOpen}/>Contract</MenuItem>
    <MenuItem to="/Action"><MenuIcon icon={faPencilAlt}/>Action</MenuItem>
    <MenuToggle onClick={() => this.CallShowOther()}><FontAwesomeIcon icon={faCaretRight}/></MenuToggle>
</MenuLine>
    :
<MenuLine id="menuline">
      <MenuItem to="/Worker"><MenuIcon icon={faIdCardAlt}/>Worker</MenuItem>
      <MenuItem to="/Product"><MenuIcon icon={faMoneyCheck}/>Product</MenuItem>
      <MenuToggle onClick={() => this.CallShowMain()}><FontAwesomeIcon icon={faCaretLeft}/></MenuToggle>
</MenuLine>
    }
</div>
    );
  }
}

