// import React from "react";
// import { FiArrowRight } from "react-icons/fi";
// import {Link} from "react-router-dom";
import styled, {css} from "styled-components"

//==================================================================================================
//　共通部品
//==================================================================================================
//--------------------------------------------------------------------------------------------------
//　ボタン系
//--------------------------------------------------------------------------------------------------
// ベーススタイル
const BaseButtonStyle = css`
  width: 112px;
  height: 37px;
  -moz-border-radius: 19px;
  -webkit-border-radius: 19px;
  border-radius: 19px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-color: var(--bkclr_btn);
  border: 1px solid var(--bkclr_btn);
  border: none;
  color: var(--clr_btn);
  font-weight: bold;
  font-size: var(--fsize_base);
  &:hover {
    background-color: var(--clr_btn);
    border: 1px solid var(--bkclr_btn);
    color: var(--bkclr_btn);
    cursor: pointer;
  }
`;

const BaseButtonStyle2 = css`
  background-color: var(--clr_btn);
  border: 1px solid var(--bkclr_btn);
  color: var(--bkclr_btn);
  &:hover {
    background-color: var(--bkclr_btn);
    border: 1px solid var(--bkclr_btn);
    border: none;
    color: var(--clr_btn);
  }
`;


// リンクボタン（濃い→淡い）
export const LnkBtn = styled.a`
  display: inline-flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  /* float:right; */
  /* margin: 5% 3% 0; */
  ${BaseButtonStyle}
  &:focus {
    outline: none;
  }
`;
export const Btn        = styled.button`
  ${BaseButtonStyle}
`;

// リンクボタン（淡い→濃い）
export const LnkBtn2 = styled(LnkBtn)`
  ${BaseButtonStyle2}
`;
export const Btn2 = styled(Btn)`
  ${BaseButtonStyle2}
`;

