import React from "react";
// import { FiArrowRight } from "react-icons/fi";
// import { Link } from "react-router-dom";

// import logoImg from "../assets/logo.svg";
// import "../styles/pages/landing.css";

import { Btn, Btn2, LnkBtn, LnkBtn2 } from "./contents"; import "./contents";

function Notfound() {
  return (
    <div id="page_notfound">
      該当ページが見つかりません。
      <Btn>Btn</Btn>
      <Btn2>Btn2</Btn2>
      <LnkBtn>LnkBtn</LnkBtn>
      <LnkBtn2>LnkBtn2</LnkBtn2>
    </div>
  );
}

export default Notfound;
