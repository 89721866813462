// import { faTshirt } from "@fortawesome/free-solid-svg-icons";
import React from "react";
// import React, {useState} from "react";
// import { FiArrowRight } from "react-icons/fi";
// import {Link, useNavigate} from "react-router-dom";
import styled from "styled-components"
// import styled, {css} from "styled-components"

// get our fontawesome imports
// import { faCaretSquareLeft, faCommentAlt, faTable, faMoneyCheck, faBuilding, faAddressCard, faPencilAlt, faHouseUser, faPaw } from "@fortawesome/free-solid-svg-icons";
// import { faCaretSquareLeft, faCommentAlt, faTable, faBuilding, faFolderOpen, faPencilAlt, faIdCardAlt, faMoneyCheck, faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
// import { faCalendar } from "@fortawesome/free-regular-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CheckBox from "./comCheckbox";

//--------------------------------------------------------------------------------------------------
//　検索項目
//--------------------------------------------------------------------------------------------------
/*---------------------------------------------------*
 * 検索エリア
 *---------------------------------------------------*/
export const SearchItemList = styled.div`
  width: calc(100% - 32px);
  font-size: var(--fsize_base);
  margin: 16px auto 0;
  & ul{
    display:-webkit-box;
    display:-ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: calc(100vh - 300px);
    overflow-y : auto;
    padding-top: 1px;
  }
  & ul li{
    border: 1px solid var(--clr_itemlst_border);
    padding: 16px;
    margin-top: -1px;
  }
  & ul li:nth-child(odd){
    width: 25%;
    background-color: var(--bkclr_itemname);
    display: flex;
    align-items: center;
  }
  & ul li:nth-child(even){
    width: 75%;
    margin-left: -1px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  & ul li:nth-child(even) div{
    margin-right: auto;
    padding: 3px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  & .srhOR{
    display: flex;
    align-items: center;
    margin: 4px;
  }
`;

/*---------------------------------*
 * 検索項目共通
 *---------------------------------*/
export const SearchItemDateLI = styled.li`
  > input[type="radio"]{
    position: relative;
    width: 20px;
    height: 20px;
    top: -2px;
  }

  > label{
    color: var(--clr_si_item);
    display: inline-block;
    width: 100px;
    font-size: var(--fsize_base);
  }

  > input[type="date"]{
    font-size: var(--fsize_base);
    padding: 3px;
  }

  > select{
    font-size: var(--fsize_base);
    border: 1px solid var(--clr_si_border);
    color: var(--clr_si_item);
    padding: 4px 3px;
    &.time{
      margin-right: 5px;
    }
    &.BumonId, .UserId{
      margin: 0 20px 0 0;
    }

  }

  > input[type="text"]{
    font-size: var(--fsize_base);
    border: 1px solid var(--clr_si_border);
    color: var(--clr_si_item);
    padding: 5px 3px 4px 7px;
    outline: none;
    &.UserNm{
      width: 250px;
    }
  }

  > span{
    &.InputTextafter{
      margin-left: 10px;
    }
  }

`;


//--------------------------------------------------------
// 日付検索項目
//--------------------------------------------------------
export class SearchItemDate extends React.Component{

  optNm  = ["",""];
  dtNm   = ["","",""];
  sthhNm = "";
  stmmNm = "";
  edhhNm = "";
  edmmNm = "";

  constructor(props){
    super(props)


    this.optNm.forEach((v,i) => v = "optSI" + this.props.no.padStart(3,'0') + "_" + String(i+1).padStart(2,'0'));
    this.dtNm.forEach( (v,i) => v = "dtSI" + this.props.no.padStart(3,'0') + "_" + String(i+1).padStart(2,'0'));
    this.sthhNm = "cmbSI"  + props.no.padStart(3,'0') + "_StHH";
    this.stmmNm = "cmbSI"  + props.no.padStart(3,'0') + "_StMM";
    this.edhhNm = "cmbSI"  + props.no.padStart(3,'0') + "_EdHH";
    this.edmmNm = "cmbSI"  + props.no.padStart(3,'0') + "_EdMM";

    this.state = {
    }
  }

  render (){
    return (
<SearchItemDateLI>
  <div>
      <input type="radio" id={this.optNm[0]} name={this.optNm[0]} defaultValue="1" /><label htmlFor={this.optNm[0]}>日付指定</label>
      <input type="date" id={this.dtNm[0]} name={this.dtNm[0]} />&nbsp;～&nbsp;<input type="date" id={this.dtNm[1]} name={this.dtNm[1]} />
  </div>
  <div>
      <input type="radio" id={this.optNm[1]} name={this.optNm[1]} defaultValue="2"/><label htmlFor={this.optNm[1]}>時刻指定</label>
      <input type="date" id={this.dtNm[2]} name={this.dtNm[2]} />&emsp;
      <select name={this.sthhNm} id={this.sthhNm} className="time">
          <option value="">--</option><option value="00">00</option><option value="01">01</option><option value="02">02</option><option value="03">03</option><option value="04">04</option><option value="05">05</option><option value="06">06</option><option value="07">07</option><option value="08">08</option><option value="09">09</option><option value="10">10</option><option value="11">11</option><option value="12">12</option><option value="13">13</option><option value="14">14</option><option value="15">15</option><option value="16">16</option><option value="17">17</option><option value="18">18</option><option value="19">19</option><option value="20">20</option><option value="21">21</option><option value="22">22</option><option value="23">23</option>
      </select>：
      <select name={this.stmmNm} id={this.stmmNm} className="time">
          <option value="">--</option><option value="00">00</option><option value="05">05</option><option value="10">10</option><option value="15">15</option><option value="20">20</option><option value="25">25</option><option value="30">30</option><option value="35">35</option><option value="40">40</option><option value="45">45</option><option value="50">50</option><option value="55">55</option>
      </select>～
      <select name={this.edhhNm} id={this.edhhNm} className="time">
          <option value="">--</option><option value="00">00</option><option value="01">01</option><option value="02">02</option><option value="03">03</option><option value="04">04</option><option value="05">05</option><option value="06">06</option><option value="07">07</option><option value="08">08</option><option value="09">09</option><option value="10">10</option><option value="11">11</option><option value="12">12</option><option value="13">13</option><option value="14">14</option><option value="15">15</option><option value="16">16</option><option value="17">17</option><option value="18">18</option><option value="19">19</option><option value="20">20</option><option value="21">21</option><option value="22">22</option><option value="23">23</option>
      </select>：
      <select name={this.edmmNm} id={this.edmmNm} className="time">
          <option value="">--</option><option value="00">00</option><option value="05">05</option><option value="10">10</option><option value="15">15</option><option value="20">20</option><option value="25">25</option><option value="30">30</option><option value="35">35</option><option value="40">40</option><option value="45">45</option><option value="50">50</option><option value="55">55</option>
      </select>
  </div>
</SearchItemDateLI>
    );
  }
}
//--------------------------------------------------------
// 社員検索項目
//--------------------------------------------------------
export class SearchItemUser extends React.Component{

  optNm  = ["",""];
  cmdBumonIdNm = "";
  cmbUserIdNm = "";
  cmbUserNmNm = "";

  constructor(props){
    super(props)

    this.optNm.forEach((v,i) => v = "optSI" + props.no.padStart(3,'0') + "_" + String(i+1).padStart(2,'0'));
    this.cmdBumonIdNm = "cmbSI" + props.no.padStart(3,'0') + "_BumonId";
    this.cmdUserIdNm  = "cmbSI" + props.no.padStart(3,'0') + "_UserId";
    this.cmdUserNmNm  = "cmbSI" + props.no.padStart(3,'0') + "_UserNm";

    this.state = {
    }
  }

  render (){
    return (

<SearchItemDateLI>
  <div>
      <input type="radio" id={this.optNm[0]} name={this.optNm[0]} defaultValue="1"/><label htmlFor={this.optNm[0]}>{this.props.title}選択</label>
      <span>
          <select name={this.cmdBumonIdNm} id={this.cmdBumonIdNm} className="BumonId">
              <option value="0">－－部署名－－</option>
              <option value="1">株式会社comam</option>
              <option value="2">&emsp;株式会社comamテクノロジー</option>
              <option value="3">&emsp;本社営業部</option>
              <option value="4">&emsp;メンテＲＦ部</option>
              <option value="5">&emsp;営業管理部</option>
              <option value="6">&emsp;総務部</option>
              <option value="7">&emsp;経理部</option>
              <option value="8">&emsp;◎メンテナンス共有</option>
              <option value="9">&emsp;メンテナンス</option>
              <option value="10">&emsp;臨時</option>
          </select>
      </span>
      <span>
          <select name={this.cmdUserIdNm} id={this.cmdUserIdNm} className="UserId">
              <option value="0">－－社員名－－</option>
              <option value="1">社員Ａさん</option>
              <option value="2">社員Ｂさん</option>
              <option value="3">社員Ｃさん</option>
          </select>
      </span>
  </div>
  <div>
      <input type="radio" id={this.optNm[1]} name={this.optNm[1]} defaultValue="2"/><label htmlFor={this.optNm[1]} >{this.props.title}入力</label>
      <input type="text" className="UserNm" id={this.cmdUserNmNm} name={this.cmdUserNmNm} placeholder="社員名を入力して下さい。" defaultValue=""/>
      <span className="InputTextafter">（前方一致）</span>
  </div>
</SearchItemDateLI>
    );
  }

}

//--------------------------------------------------------
// 数値型検索項目
//--------------------------------------------------------
export class SearchItemNumber extends React.Component{

  txtNm = '';

  constructor(props){
    super(props)

    this.txtNm = "iptSI" + props.no.padStart(3,'0') + "_Code";

    this.state = {
    }
  }

  render (){
    return (
<SearchItemDateLI>
  <div>
      <input type="text" className="Code" id={this.txtNm} name={this.txtNm} placeholder={this.props.placeholder} defaultValue={this.props.value} />
      <span className="InputTextafter">（前方一致）</span>
  </div>
</SearchItemDateLI>
    );
  }
}

//--------------------------------------------------------
// テキスト型検索項目
//--------------------------------------------------------
export class SearchItemText extends React.Component{

  txtNm = '';
  chkNm = '';

  constructor(props){
    super(props)

    this.txtNm = "iptSI" + props.no.padStart(3,'0') + "_Text";
    this.chkNm = "chkSI" + props.no.padStart(3,'0') + "_PartSrh";

    this.state = {
    }
  }

  render (){
    return (
<SearchItemDateLI>
    <div>
        <input type="text" className="Text" id={this.txtNm} name={this.txtNm} placeholder={this.props.placeholder} defaultValue={this.props.value} />
        <span className="InputTextafter">（前方一致）</span>
        <CheckBox nm={this.chkNm} lbnm="部分一致" checked={this.props.checked} />
    </div>
</SearchItemDateLI>
    );
  }
}


//--------------------------------------------------------
// チェックボックスリスト検索項目
//--------------------------------------------------------
export class SearchItemCklst extends React.Component{

  constructor(props){
    super(props)

    this.state = {
    }
  }

  render (){
    const { list, no } = this.props;

    return (
<SearchItemDateLI>
    <div>
    {list.map((item) => (
      <CheckBox nm={"chkSI" + no.padStart(3,'0') + "_target" + item.id.padStart(2,'0')} lbnm={item.title} checked={item.checked} />
    ))}
    </div>
</SearchItemDateLI>
    );
  }
}






