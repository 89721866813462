import React, {useState} from "react";
// import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
// import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

// import styled from "styled-components"

import {Header, Menu} from "./partsHeader"
import {Main, FuncLine, FuncTitle} from "./partsMain"


import { faCalendar } from "@fortawesome/free-regular-svg-icons";



function Calender() {

  var [tabidx, changeTabidx] = useState(0);

  var [tablst] = useState([
    {id: 0, name: "fctab0", title: "スケジュール", checked: true},
    {id: 1, name: "fctab1", title: "ToDo"     , checked: false},
    {id: 2, name: "fctab2", title: "新規登録"  , checked: false},
  ]);

  let unm = "臨時アカウント";
  let pgnavi = ["Calender","スケジュール"].join(" ＞ ");
  let navigate = useNavigate();

  return(
<div>
  <Header unm={unm} pgnavi={pgnavi} navigate={navigate} />
  <Menu isMainMenu={true} />
  <Main>
    <FuncLine icon={faCalendar} title="CALENDER" tablst={tablst} changeTabidx={changeTabidx} />
    <FuncTitle />
    <div>tabidx={tabidx}</div>
  </Main>
</div>
  );

}

export default Calender;

