import React from "react";
// import React, {useState} from "react";
// import { FiArrowRight } from "react-icons/fi";
// import {Link, useNavigate} from "react-router-dom";
// import styled, {css} from "styled-components"
import styled from "styled-components"

// get our fontawesome imports
// import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Select from "./comSelect";
// import { render } from "@testing-library/react";

//==================================================================================================
//　メイン共通定義
//==================================================================================================
//--------------------------------------------------------------------------------------------------
//　メインエリア
//--------------------------------------------------------------------------------------------------
//------------------------------------------------
// CSS定義
//------------------------------------------------
export const Main = styled.main`
  margin: 8px 1% 0px;
  overflow: hidden;
  height: calc(100vh - 40px - 32px - 8px); /* ヘッダ部 40px + 32px  メインのTopマージン 8px */
`;

//--------------------------------------------------------------------------------------------------
//　機能タブ
//--------------------------------------------------------------------------------------------------
//------------------------------------------------
// CSS定義
//------------------------------------------------
const FuncLineStyle = styled.div`
  width: 100%;
  height: 32px;
  display:-webkit-box;
  display:-ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: var(--fsize_funcline);
  border-bottom: 1px solid var(--clr_funcline);
  div.fc_icon{
    color: var(--clr_base03);
    margin-top: 8px;
    margin-right: 8px;
    margin-left: 4px;
    text-align: right;
    font-size: 16px;
    width: 16px;
    min-width: 16px;
    max-width: 16px;
  }
  div.fc_ttl{
    width: 112px;
    /* padding-left: 20px; */
    padding-top: 11px;
    font-size: var(--fsize_funcline_ttl);
    font-weight: 700;
    letter-spacing: 0.5px;
  }
  div.fc_tablst{
    display:-webkit-box;
    display:-ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  div.fc_tablst div{
    display: flex;
  }
  div.fc_tablst input[type="radio"]{
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
  }
  div.fc_tablst label{
    position: relative;
    border: 1px solid var(--clr_functab_border);
    border-bottom: none;
    padding: 9px 0px 0px;
    border-radius: 8px 8px 0 0;
    background: var(--bkclr_functab);
    color: var(--clr_functab);
    font-size: var(--fsize_functab);
    margin-right: 8px;
    cursor: pointer;
    width: 120px;
    text-align: center;
    box-sizing: border-box;
    &::before{
      position: absolute;
      content: "";
      background: var(--clr_base05);
      /* background: rgba(0,32,64,0.2); */
      /* opacity: 0.9; */
      height: 0;
      right: -1px;
      left: -1px;
      bottom: 0;
      border: 1px solid var(--clr_base05);
      border-radius: 8px 8px 0 0;
      transition: .4s;
      z-index: -1;
      background-image: linear-gradient(180deg, rgba(255,255,255,0.4), transparent);
    }
  }
  div.fc_tablst label:hover{
    opacity: 0.9;
    font-weight: 300;
    color: var(--clr_base_white);
    /* font-size: var(--fsize_functab_hover); */
    /* font-weight: 700; */
    /* transform: scaleY(1.3); */
    &::before{
      height: 100%;
    }
  }

  div.fc_tablst input[type="radio"]:checked + label{
    background: var(--bkclr_functab_check);
    color: var(--clr_functab_check);
  }
`;

//------------------------------------------------
// 関数定義
//------------------------------------------------
export class FuncLine extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      changeTabidx : this.props.changeTabidx,
    };
  }

  render(){

    return(
<FuncLineStyle>
  <div className="fc_icon"><FontAwesomeIcon icon={this.props.icon}/></div>
  <div className="fc_ttl">{this.props.title}</div>
  <div className="fc_tablst">
    {this.props.tablst.map((lst) => (
        <div key={lst.id}><input type="radio" id={lst.name} name="fctab" defaultValue={lst.id} defaultChecked={lst.checked} onClick={() => this.state.changeTabidx(lst.id)} /><label htmlFor={lst.name}>{lst.title}</label></div>
    ))}
  </div>
</FuncLineStyle>
    );
  };
}


//--------------------------------------------------------------------------------------------------
//　機能タイトル
//--------------------------------------------------------------------------------------------------
//------------------------------------------------
// CSS定義
//------------------------------------------------
const FuncTitleStyle = styled.div`
  font-size: var(--fsize_base);
  padding: 4px 16px;
  background-color: var(--clr_base_white);
  border: 2px solid var(--clr_base04);
  border-left: none;
  border-right: none;
  display: flex;
  align-items: center;
  height: 32px;
  & div{
    font-weight: 700;
  }
  & div span{
    font-weight: 700;
  }
  & div span:first-child{
    margin-right: 16px;
  }
  & div.dspsrhnote{
    margin-left: 20px;
    color: var(--clr_functtl_notes);
  }
  & div.dspcnt{
    margin-left: auto;
  }
`;

//------------------------------------------------
// 関数定義
//------------------------------------------------
export class FuncTitle extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      // title       :  this.props.title,
      // dspsrhnote   : this.props.dspsrhnote,
      dashlst      : this.props.dashlst,
      dsplst       : this.props.dsplst,
      changeListno : this.props.changeListno,
      changeDspcnt : this.props.changeDspcnt,
      // totalcnt     : this.props.totalcnt,
    }
  }

  render() {

    const htmlTitle =
      this.props.title ? <div>{this.props.title}</div> : null;
    const htmlDspsrhnote =
      this.props.dspsrhnote ? <div className="dspsrhnote">表示条件：{this.props.dspsrhnote}</div> : null;
    const htmlDashlst =
      this.props.dashlst ? <div><Select name="cmbBoardList" id="cmbBoardList" list={this.state.dashlst} selectedIndex={0} callbackChange={this.props.changeListno} /></div> : null;
    const htmlDspcnt =
      this.props.dsplst ?
<div className="dspcnt">
  <span>該当件数：{this.props.totalcnt}件</span>
  <span>
    <Select name="cmbViewCnt" id="cmbViewCnt" list={this.state.dsplst}  selectedIndex={50} callbackChange={this.props.changeDspcnt} />
  </span>
</div>
  : null;


    return(
<FuncTitleStyle>
  {htmlTitle}
  {htmlDspsrhnote}
  {htmlDashlst}
  {htmlDspcnt}
</FuncTitleStyle>
    );
  }
}